
import {computed, defineComponent, ref} from "vue";
import {debounce} from 'lodash';
import {
  IKhoResponseBase,
  IPhieuNhapKhoResponseBase,
  IPhieuXuatKhoResponseBase,
  IVatTuTrongKhoResponseBase
} from "@/core/interfaces/ApiResponses";
import {KhoService} from "@/core/services/Kho.service";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {useRouterCustom} from "@/core/hooks/use-router-custom";
import {getErrorMsg, swalNotification} from '@/core/helpers/utils';
import {useLoading} from "@/core/hooks/use-loading";
import {usePagination} from '@/core/hooks/use-pagination';
import {VatTuService} from '@/core/services/VatTu.service';
import {useButtonCustom} from '@/core/hooks/use-button-custom';
import {PhieuNhapKhoService} from '@/core/services/PhieuNhapKho.service';
import {AddVatTuItemsFor} from '@/core/enums/addVatTuItemsFor.enum';

import moment from "moment";
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog.vue';
import AddVatTuItemV2 from '../../../../components/add-vat-tu-items-v2/AddVatTuItemV2.vue';
import VatTuKhoTable from './vat-tu-kho-table/VatTuKhoTable.vue';
import PhieuNhapKhoTableV2 from '../phieu-nhap-kho/phieu-nhap-kho-table/PhieuNhapKhoTableV2.vue';
import PhieuNhapPrintModal from '../../../../components/shared/phieu-nhap-print-modal/PhieuNhapPrintModal.vue';
import Pagination from '../../../../components/shared/pagination/Pagination.vue';
import PerPageSelection from '../../../../components/shared/per-page-selection/PerPageSelection.vue';
import LabelCustom from '../../../../components/shared/label-custom/LabelCustom.vue';
import PhieuXuatKhoService from '../../../../core/services/Receipt.service';
import PhieuXuatKhoTable from '../receipts/phieu-xuat-kho-table/PhieuXuatKhoTable.vue';
import ResponsiveLayout from '@/layout/_shared/ResponsiveLayout.vue';
import TaiKhoanSelectionV2 from '@/components/shared/tai-khoan-selection/TaiKhoanSelectionV2.vue';

export default defineComponent({
  name: 'warehouse-detail',

  components: {
    ButtonCustom,
    PageRowLayout,
    ConfirmationDialog,
    AddVatTuItemV2,
    LabelCustom,
    VatTuKhoTable,
    PhieuNhapKhoTableV2,
    PhieuNhapPrintModal,
    PerPageSelection,
    Pagination,
    PhieuXuatKhoTable,
    ResponsiveLayout,
    TaiKhoanSelectionV2,
  },

  setup() {
    const {goBack, push, replace, id: warehouseId} = useRouterCustom();
    const {startLoading, endLoading} = useLoading();
    const {
      currentPage: currentPageVatTu,
      totalItems: totalVatTuItems,
      perPage: perPageVatTu,
      perPageArr: perPageArrVatTu,
      loading: loadingVatTu,
      searchTerm
    } = usePagination();

    const {
      currentPage: currentPagePhieuNhap,
      totalItems: totalPhieuNhapItems,
      perPage: perPagePhieuNhap,
      perPageArr: perPageArrPhieuNhap,
      loading: loadingPhieuNhap,
      searchTerm: searchTermPhieuNhap
    } = usePagination();

    const {
      currentPage: currentPagePhieuXuat,
      totalItems: totalPhieuXuatItems,
      perPage: perPagePhieuXuat,
      perPageArr: perPageArrPhieuXuat,
      loading: loadingPhieuXuat,
      searchTerm: searchTermPhieuXuat
    } = usePagination();

    const {ButtonsType, ButtonTypeColors} = useButtonCustom();

    const departmentName = ref('');

    const warehouseDetail = ref<IKhoResponseBase | null>(null);

    const phieuNhapKhoItems = ref<IPhieuNhapKhoResponseBase[]>([]);

    const phieuXuatKhoItems = ref<IPhieuXuatKhoResponseBase[]>([]);

    const fetchWarehouseDetail = async () => {
      try {
        startLoading();
        const {
          data: {data: warehouseRes},
        } = await KhoService.get(warehouseId.value);
        warehouseDetail.value = {...warehouseRes};
        departmentName.value = warehouseDetail.value.don_vi.ten_don_vi;
        endLoading();
      } catch (e) {
        endLoading();
        await swalNotification(
            getErrorMsg(e, 'Xin lỗi, có vẻ có lỗi xảy khi lấy thông tin kho, mời bạn thực hiện lại'),
            'error',
        );
      }
    };

    return {
      warehouseId,
      warehouseDetail,
      phieuNhapKhoItems,
      phieuXuatKhoItems,
      departmentName,
      formatDate: computed(() => moment),
      fetchWarehouseDetail,
      goBack,
      push,
      replace,
      startLoading,
      endLoading,
      currentPageVatTu,
      totalVatTuItems,
      perPageVatTu,
      perPageArrVatTu,
      loadingVatTu,
      searchTerm,
      currentPagePhieuNhap,
      totalPhieuNhapItems,
      perPagePhieuNhap,
      perPageArrPhieuNhap,
      loadingPhieuNhap,
      searchTermPhieuNhap,
      currentPagePhieuXuat,
      totalPhieuXuatItems,
      perPagePhieuXuat,
      perPageArrPhieuXuat,
      loadingPhieuXuat,
      searchTermPhieuXuat,
      ButtonsType,
      ButtonTypeColors,
    };
  },

  data: () => ({
    AddVatTuItemsFor,
    idPhieuNhapDetail: null as null | number,
    isDeletingModalDisplay: false,
    isAddVatTuModalDisplay: false,
    isPhieuNhapModalDisplay: false,
    vatTuItemsDisplaying: [] as IVatTuTrongKhoResponseBase[],
    openingPhieuNhap: null as IPhieuNhapKhoResponseBase | null,
    deletingVatTu: null as IVatTuTrongKhoResponseBase | null,
  }),

  watch: {
    async currentPageVatTu() {
      await this.fetchVatTuKhoItems();
    },

    async perPageVatTu() {
      await this.fetchVatTuKhoItems();
    },

    async currentPagePhieuNhap() {
      await this.fetchPhieuNhapItems();
    },

    async perPagePhieuNhap() {
      await this.fetchPhieuNhapItems();
    },

    async currentPagePhieuXuat() {
      await this.fetchPhieuXuatItems();
    },

    async perPagePhieuXuat() {
      await this.fetchPhieuXuatItems();
    },
  },

  async mounted() {
    setCurrentPageTitle('Quản lý Kho');

    await this.fetchWarehouseDetail();

    await this.fetchVatTuKhoItems();

    await this.fetchPhieuNhapItems();

    await this.fetchPhieuXuatItems();

    this.idPhieuNhapDetail = +this.$route.query['chi-tiet-phieu-nhap'];

    if (this.idPhieuNhapDetail) {
      const {data: {data}} = await PhieuNhapKhoService.get(this.idPhieuNhapDetail);

      this.openingPhieuNhap = data;

      this.isPhieuNhapModalDisplay = true;
    }
  },

  computed: {
    vatTuKhoComputed(): IVatTuTrongKhoResponseBase[] {
      return this.vatTuItemsDisplaying.map(vatTu => ({
        ...vatTu,
        pivot: {
          don_gia: vatTu.pivot.don_gia,
          so_luong: vatTu.pivot.so_luong,
          id_kho: this.warehouseId,
          id_vat_tu: vatTu.id,
        }
      }));
    },
  },

  methods: {
    onDeleteVatTuButtonClicked({vatTu}: { vatTu: IVatTuTrongKhoResponseBase }) {
      this.isDeletingModalDisplay = true;

      this.deletingVatTu = vatTu;
    },

    async fetchVatTuKhoItems() {
      try {
        this.startLoading();
        const {data: {data: {data: items, total}}} = await VatTuService.getVatTuItemsByKhoId({
          idKho: this.warehouseId,
          page: this.currentPageVatTu,
          perPage: this.perPageVatTu,
          searchTerm: this.searchTerm,
        });

        this.vatTuItemsDisplaying = items;
        this.totalVatTuItems = total;

        this.endLoading();
      } catch (e) {
        this.endLoading();
        await swalNotification(
            getErrorMsg(e, 'Có lỗi xảy ra, không thể lấy danh sách vật tư'),
            'error'
        );
      }
    },

    async fetchPhieuNhapItems() {
      try {
        this.startLoading();
        const {data: {data: {data: items, total}}} = await PhieuNhapKhoService.getListByKhoId({
          id_kho: this.warehouseId,
          current_page: this.currentPagePhieuNhap,
          per_page: this.perPagePhieuNhap,
          search: this.searchTermPhieuNhap,
        });

        this.phieuNhapKhoItems = items;
        this.totalPhieuNhapItems = total;

        this.endLoading();
      } catch (e) {
        this.endLoading();
        await swalNotification(
            getErrorMsg(e, 'Có lỗi xảy ra, không thể lấy danh sách phiếu nhập kho'),
            'error'
        );
      }
    },

    async fetchPhieuXuatItems() {
      try {
        this.startLoading();

        const {data: {data: {data: items, total}}} = await PhieuXuatKhoService.getListByKhoId({
          id_kho: this.warehouseId,
          current_page: this.currentPagePhieuXuat,
          per_page: this.perPagePhieuXuat,
          search: this.searchTermPhieuXuat,
        });

        this.phieuXuatKhoItems = items;
        this.totalPhieuXuatItems = total;

        this.endLoading();
      } catch (e) {
        this.endLoading();
        await swalNotification(
            getErrorMsg(e, 'Có lỗi xảy ra, không thể lấy danh sách phiếu xuất kho kho'),
            'error'
        );
      }
    },

    onPhieuNhapDetailButtonClicked({phieuNhapKhoData}: { phieuNhapKhoData: IPhieuNhapKhoResponseBase }) {
      this.isPhieuNhapModalDisplay = true;
      this.openingPhieuNhap = phieuNhapKhoData;
    },

    onClosePrintModal() {
      this.isPhieuNhapModalDisplay = false;
      this.openingPhieuNhap = null;
    },

    onAddVatTuButtonClicked() {
      this.isAddVatTuModalDisplay = true;
    },

    async onAddedVatTuItems() {
      this.isAddVatTuModalDisplay = false;
      this.startLoading();
      await this.fetchWarehouseDetail();
      this.endLoading();
    },

    async confirmDelete() {
      try {
        this.startLoading()
        await KhoService.removeVatTuKho({
          id_kho: this.warehouseId,
          id_vat_tu: this.deletingVatTu?.id || 0,
        });
        this.endLoading();
        this.isDeletingModalDisplay = false;
        await swalNotification(
            'Xóa thành công',
            'success'
        );
        this.startLoading()
        await this.fetchWarehouseDetail();
        this.endLoading();
        this.deletingVatTu = null;
      } catch (e) {
        this.endLoading();
        this.isDeletingModalDisplay = false;
        await swalNotification(
            getErrorMsg(e, 'Có lỗi xảy ra, không thể xóa vật tư này'),
            'error'
        );
      }
    },

    async onVatTuIntoKhoAdded() {
      this.currentPageVatTu = 1;
      this.isAddVatTuModalDisplay = false;

      this.fetchVatTuKhoItems()
    },

    search: debounce(function () {
      this.fetchVatTuKhoItems();
    }, 500),

    searchPhieuNhap: debounce(function () {
      this.fetchPhieuNhapItems();
    }, 500),

    searchPhieuXuat: debounce(function () {
      this.fetchPhieuXuatItems();
    }, 500),
  },
});
